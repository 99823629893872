import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// import tw from 'twin.macro'

//internal
import Layout from "../components/layout"
import SEO from "../components/seo"
import Btn from '../components/button'




const LandingPageTemplate = ({data, location}) => {
  const page = data.page
  const siteTitle = data.site.siteMetadata.title
  const desc = data.page.copy.copy


  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title={page.title} description={desc} />
      <article id="blog" tw="mx-auto">
        <header tw="text-center my-16">
          <h1 tw="font-black text-5xl">
              {page.title}
          </h1>
        </header>
        {
          (page.heroImage == null)
          ? <div></div>
          : <Img fluid={page.heroImage.fluid} alt={page.heroImage.title} tw="rounded max-h-800" />
        }
        {
         page.body ?
          <section dangerouslySetInnerHTML={{ __html: page.body.childMarkdownRemark.html }} tw="md:w-3/5 mx-auto mt-24"/>
          : <div></div>
        }
        <Btn link={page.ctaLink} text={page.ctaTitle} />

      </article>


    </Layout>
  )
}

export default LandingPageTemplate;

export const pageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    page:contentfulLandingPage(slug: {eq: $slug}) {
      copy {
        copy
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      ctaLink
      ctaTitle
      headline
      slug
      title
    } 

  }
`
